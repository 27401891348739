import React, { useState } from 'react';
import './faqs.scss';
import {useTranslation} from 'react-i18next';

function Faqs() {

  const {t} = useTranslation();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);

  const toggleDD = (questionNumber) => {
    switch (questionNumber) {
      case 1:
        setIsOpen1((prevOpen) => !prevOpen);
        break;
      case 2:
        setIsOpen2((prevOpen) => !prevOpen);
        break;
      case 3:
        setIsOpen3((prevOpen) => !prevOpen);
        break;
      case 4:
        setIsOpen4((prevOpen) => !prevOpen);
        break;
      case 5:
        setIsOpen5((prevOpen) => !prevOpen);
        break;
      case 6:
        setIsOpen6((prevOpen) => !prevOpen);
        break;
      case 7:
        setIsOpen7((prevOpen) => !prevOpen);
        break;
      case 8:
        setIsOpen8((prevOpen) => !prevOpen);
        break;
      case 9:
        setIsOpen9((prevOpen) => !prevOpen);
        break;
      case 10:
        setIsOpen10((prevOpen) => !prevOpen);
        break;
      case 11:
        setIsOpen11((prevOpen) => !prevOpen);
        break;

      default:
        break;
    }
  };

  return (
    <section className="faqs dark">
      <div className="inner">
        <h2 className="no-caps">{t('faqs.title')}</h2>
        <dl>
          <div className={isOpen1 ? 'open' : ''}>
            <dt onClick={() => toggleDD(1)}>{t('faqs.question1')}</dt>
            <dd>
              <p>{t('faqs.answer1-1')}</p>
              <p>{t('faqs.answer1-2')}</p>
              <p>{t('faqs.answer1-3')}</p>
            </dd>
          </div>
          <div className={isOpen2 ? 'open' : ''}>
            <dt onClick={() => toggleDD(2)}>{t('faqs.question2')}</dt>
            <dd>
              <p>{t('faqs.answer2-1')}</p>
              <p>{t('faqs.answer2-2')}</p>
              <p>{t('faqs.answer2-3')}</p>
            </dd>
          </div>
          <div className={isOpen3 ? 'open' : ''}>
            <dt onClick={() => toggleDD(3)}>{t('faqs.question3')}</dt>
            <dd><p>{t('faqs.answer3')}</p></dd>
          </div>
          <div className={isOpen4 ? 'open' : ''}>
            <dt onClick={() => toggleDD(4)}>{t('faqs.question4')}</dt>
            <dd>
              <p>{t('faqs.answer4-1')}</p>
              <p>{t('faqs.answer4-2')}</p>
            </dd>
          </div>
          <div className={isOpen5 ? 'open' : ''}>
            <dt onClick={() => toggleDD(5)}>{t('faqs.question5')}</dt>
            <dd><p>{t('faqs.answer5')}</p></dd>
          </div>
          <div className={isOpen6 ? 'open' : ''}>
            <dt onClick={() => toggleDD(6)}>{t('faqs.question6')}</dt>
            <dd><p>{t('faqs.answer6')}</p></dd>
          </div>
          <div className={isOpen7 ? 'open' : ''}>
            <dt onClick={() => toggleDD(7)}>{t('faqs.question7')}</dt>
            <dd><p>{t('faqs.answer7')}</p></dd>
          </div>
          <div className={isOpen8 ? 'open' : ''}>
            <dt onClick={() => toggleDD(8)}>{t('faqs.question8')}</dt>
            <dd><p>{t('faqs.answer8')}</p></dd>
          </div>
          <div className={isOpen9 ? 'open' : ''}>
            <dt onClick={() => toggleDD(9)}>{t('faqs.question9')}</dt>
            <dd><p>{t('faqs.answer9')}</p></dd>
          </div>
          <div className={isOpen10 ? 'open' : ''}>
            <dt onClick={() => toggleDD(10)}>{t('faqs.question10')}</dt>
            <dd><p>{t('faqs.answer10')}</p></dd>
          </div>
          <div className={isOpen11 ? 'open' : ''}>
            <dt onClick={() => toggleDD(11)}>{t('faqs.question11')}</dt>
            <dd>
              <p>{t('faqs.answer11-1')}</p>
              <p>{t('faqs.answer11-2')}</p>
              <p>{t('faqs.answer11-3')}</p>
              <p>{t('faqs.answer11-4')}</p>
              <p>{t('faqs.answer11-5')}</p>
            </dd>
          </div>
        </dl>
      </div>
    </section>
  );
}

export default Faqs;
import './main.scss';
import React from 'react';
import Header from '../../organisms/header/header.js'
import Hero from '../../organisms/hero/hero.js'
import Intro from '../../organisms/intro/intro.js'
import Why from '../../organisms/why/why.js'
import Members from '../../organisms/members/members.js'
import YourRole from '../../organisms/your-role/your-role.js'
import Benefits from "../../organisms/benefits/benefits.js";
import Faqs from "../../organisms/faqs/faqs.js";

const Main = () => {

  return (
    <div className="App">
      <Header />
      <main>
        <Hero />
        <Intro />
        <Why />
        <Members />
        <Benefits />
        <YourRole />
        <Faqs />
      </main>
    </div>
  )
}

export default Main;
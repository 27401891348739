import React, {useEffect, useState} from 'react';
import './benefits.scss';
import {useTranslation} from 'react-i18next';
import ImageDeco1200 from '../../../assets/img/deco-benefits-1200.jpg';
import ImageDeco767 from '../../../assets/img/deco-benefits-767.jpg';
import Slider from 'react-slick';

function Benefits() {

  /* Traducciones */
  const {t} = useTranslation();

  /* Ancho de pantalla */
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);

  /* Slick slider react */
  const [sliderNav, setNav] = useState();
  const [sliderContent, setContent] = useState();

  const settingsNav = {
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          infinite: false,
          variableWidth: true,
          focusOnSelect: true,
          swipeToSlide: true,
          slidesToShow: 5,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-content'
        }
      },
      {
        // breakpoint para el slider de beneftis cambiar tambien en variables.scss buscando el nombre de esta variable ($custom-slider-beneficts)
        breakpoint: 1210,

        settings: {
          infinite: false,
          variableWidth: true,
          focusOnSelect: true,
          swipeToSlide: true,
          slidesToShow: 1,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-content'
        }
      }
    ]
  }
  const settingsContent = {
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          infinite: false,
          focusOnSelect: true,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-nav'
        }
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          focusOnSelect: true,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-nav'
        }
      }
    ]
  }


  useEffect(() => {
    const handleResize = () => {
      setAnchoPantalla(window.innerWidth);
    };

    // Agregar un listener para el evento de cambio de tamaño de pantalla
    window.addEventListener('resize', handleResize);

    // Eliminar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="benefits dark">
      <div className="inner">
        <h2>{t('benefits.title')}</h2>
        <p>{t('benefits.text')}</p>
        <div className="img">
          {anchoPantalla < 768 ? (
            <img
              src={ImageDeco767}
              alt=""
            />
          ) : (
            <img
              src={ImageDeco1200}
              alt=""
            />
          )}
        </div>

        <Slider className="benefits-nav" {...settingsNav} asNavFor={sliderContent}
                ref={(slider1) => setNav(slider1)}>
          <div>{t('benefits.tab1.name')}</div>
          <div>{t('benefits.tab2.name')}</div>
          <div>{t('benefits.tab3.name')}</div>
          <div>{t('benefits.tab4.name')}</div>
          <div>{t('benefits.tab5.name')}</div>
        </Slider>
        <Slider className="benefits-content" {...settingsContent} asNavFor={sliderNav}
                ref={(slider2) => setContent(slider2)}>
          <div className="content">
            <h4>{t('benefits.tab1.title')}</h4>
            <div className="text">
              <p><span>{t('benefits.tab1.subTitle1')}</span> {t('benefits.tab1.text1')}</p>
              <p><span>{t('benefits.tab1.subTitle2')}</span> {t('benefits.tab1.text2')}</p>
              <p><span>{t('benefits.tab1.subTitle3')}</span> {t('benefits.tab1.text3')}</p>
              <p><span>{t('benefits.tab1.subTitle4')}</span> {t('benefits.tab1.text4')}</p>
            </div>
          </div>
          <div className="content">
            <h4>{t('benefits.tab2.title')}</h4>
            <div className="text">
              <p>{t('benefits.tab2.text1')}</p>
              <p>{t('benefits.tab2.text2')}</p>
            </div>
          </div>
          <div className="content">
            <h4>{t('benefits.tab3.title')}</h4>
            <div className="text">
              <p><span>{t('benefits.tab3.subTitle1')}</span> {t('benefits.tab3.text1')}</p>
              <p><span>{t('benefits.tab3.subTitle2')}</span> {t('benefits.tab3.text2')}</p>
              <p><span>{t('benefits.tab3.subTitle3')}</span> {t('benefits.tab3.text3')}</p>
              <p><span>{t('benefits.tab3.subTitle4')}</span> {t('benefits.tab3.text4')}</p>
              <p><span>{t('benefits.tab3.subTitle5')}</span> {t('benefits.tab3.text5')}</p>
              <p><span>{t('benefits.tab3.subTitle6')}</span> {t('benefits.tab3.text6')}</p>
            </div>
          </div>
          <div className="content">
            <h4>{t('benefits.tab4.title')}</h4>
            <div className="text">
              <p>{t('benefits.tab4.text1')}</p>
            </div>
          </div>
          <div className="content">
            <h4>{t('benefits.tab5.title')}</h4>
            <div className="text">
              <p><span>{t('benefits.tab5.subTitle1')}</span> {t('benefits.tab5.text1')}</p>
              <p><span>{t('benefits.tab5.subTitle2')}</span> {t('benefits.tab5.text2')}</p>
              <p><span>{t('benefits.tab5.subTitle3')}</span> {t('benefits.tab5.text3')}</p>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default Benefits;
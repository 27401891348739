import './assets/scss/styles.scss';
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import esTranslations from './assets/lang/es.json';
import enTranslations from './assets/lang/en.json';
import ptTranslations from './assets/lang/pt.json';
import deDETranslations from './assets/lang/de-DE.json';
import deATTranslations from './assets/lang/de-AT.json';
import frFRTranslations from './assets/lang/fr-FR.json';
import frBETranslations from './assets/lang/fr-BE.json';
import duTranslations from './assets/lang/du.json';
import itTranslations from './assets/lang/it.json';
import trTranslations from './assets/lang/tr.json';
import csTranslations from './assets/lang/cs.json';
import skTranslations from './assets/lang/sk.json';

import Main from './components/pages/main/main';
import Cookies from "js-cookie";


i18n
  .use(initReactI18next)
  .init({
    resources: {
      "en": {
        translation: enTranslations
      },
      "es": {
        translation: esTranslations
      },
      "pt": {
        translation: ptTranslations
      },
      "de-DE": {
        translation: deDETranslations
      },
      "de-AT": {
        translation: deATTranslations
      },
      "fr-FR": {
        translation: frFRTranslations
      },
      "fr-BE": {
        translation: frBETranslations
      },
      "du": {
        translation: duTranslations
      },
      "it": {
        translation: itTranslations
      },
      "tr": {
        translation: trTranslations
      },
      "cs": {
        translation: csTranslations
      },
      "sk": {
        translation: skTranslations
      }
    },
    lng: Cookies.get('language') || navigator.language, // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main/>}/>
      </Routes>
    </Router>
  );
}

export default App;
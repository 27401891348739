import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

function LanguageSwitcher() {
  const { i18n, t } = useTranslation();

  // Función para obtener el idioma almacenado en la cookie
  const getLanguageFromCookie = () => {
    return Cookies.get('language') || null;
  };

  // Estado inicial del idioma seleccionado, usando una función para obtenerlo desde la cookie
  const [selectedLanguage, setSelectedLanguage] = useState(() => getLanguageFromCookie() || navigator.language);
  //console.log(navigator.language)

  useEffect(() => {
    //console.log(selectedLanguage);
    changeLanguage(selectedLanguage);
    // Esto sirve para eliminar el warning React Hook useEffect has missing dependencies: 'changeLanguage' and
    // 'getLanguageFromCookie'. Either include them or remove the dependency array  react-hooks/exhaustive-deps:

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const setLanguageCookie = (lng) => {
    Cookies.set('language', lng, { expires: 365 });
  };

  const changeLanguage = (lng) => {
    setLanguageCookie(lng);
    i18n.changeLanguage(lng);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
  };

  return (
    <select
      className="language-switcher"
      id="language"
      value={selectedLanguage}
      onChange={handleLanguageChange}
    >
      <option value="de-AT">{t('languages.de-AT')}</option>
      <option value="fr-BE">{t('languages.fr-BE')}</option>
      <option value="cs">{t('languages.cs')}</option>
      <option value="de-DE">{t('languages.de-DE')}</option>
      <option value="du">{t('languages.du')}</option>
      <option value="en">{t('languages.en')}</option>
      <option value="es">{t('languages.es')}</option>
      <option value="fr-FR">{t('languages.fr-FR')}</option>
      <option value="it">{t('languages.it')}</option>
      <option value="pt">{t('languages.pt')}</option>
      <option value="sk">{t('languages.sk')}</option>
      <option value="tr">{t('languages.tr')}</option>
    </select>
  );
}

export default LanguageSwitcher;

import React, { useEffect, useState } from 'react';
import './your-role.scss';
import {useTranslation} from 'react-i18next';
import IcoStep1 from '../../../assets/img/icons/ico-step1.svg'
import IcoStep2 from '../../../assets/img/icons/ico-step2.svg'
import IcoStep3 from '../../../assets/img/icons/ico-step3.svg'

function YourRole() {

  const {t} = useTranslation();

  const [topValue, setTopValue] = useState(0);

  useEffect(() => {
    const scroll = document.getElementById('scroll');
    const halfToTop = window.innerHeight/2;
    const handleScroll = () => {
      // Acciones que deseas realizar cuando se hace scroll
      let scrollBottom = scroll.getBoundingClientRect().top + scroll.getBoundingClientRect().height;
      if (scroll.getBoundingClientRect().top <= halfToTop-100 && scrollBottom >= (halfToTop-100 + 296)) {
        // mueve el indicador
        setTopValue(halfToTop-100 - scroll.getBoundingClientRect().top);
      }
      if (scroll.getBoundingClientRect().top > halfToTop-100) {
        // para el indicador en el top
        setTopValue(0);
      }
      if (scrollBottom < (halfToTop-100 + 296)) {
        ///para el indicador en el bottom
        setTopValue(scroll.getBoundingClientRect().height - 296);
      }
    };

    // Agrega un event listener al evento de scroll
    window.addEventListener('scroll', handleScroll);

    // Limpia el event listener al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // El segundo parámetro vacío indica que el efecto se ejecutará solo una vez al montar el componente

  return (
    <section className="your-role">
      <div className="inner">
        <h2>{t('your-role.title')}</h2>
        <p>{t('your-role.text')}</p>
        <div className="stepper">

          <span className="scroll" id="scroll"><span style={{top: `${topValue}px`}}></span></span>

          <div className="step1">
            <h4><img src={IcoStep1} alt="1"/>{t('your-role.step1.title')}</h4>
            <h5>{t('your-role.step1.subtitle1')}</h5>
            <p>{t('your-role.step1.subtext1-1')}</p>
            <p>{t('your-role.step1.subtext1-2')}</p>
            <h5>{t('your-role.step1.subtitle2')}</h5>
            <p>{t('your-role.step1.subtext2-1')}</p>
            <p>{t('your-role.step1.subtext2-2')}</p>
            <p>{t('your-role.step1.subtext2-3')}</p>
          </div>
          <div className="step2">
            <h4><img src={IcoStep2} alt="1"/>{t('your-role.step2.title')}</h4>
            <h5>{t('your-role.step2.subtitle1')}</h5>
            <p>{t('your-role.step2.subtext1')}</p>
            <h5>{t('your-role.step2.subtitle2')}</h5>
            <p>{t('your-role.step2.subtext2')}</p>
            <h5>{t('your-role.step2.subtitle3')}</h5>
            <p>{t('your-role.step2.subtext3')}</p>
          </div>
          <div className="step3">
            <h4><img src={IcoStep3} alt="1"/>{t('your-role.step3.title')}</h4>
            <h5>{t('your-role.step3.subtitle1')}</h5>
            <p>{t('your-role.step3.subtext1')}</p>
            <h5>{t('your-role.step3.subtitle2')}</h5>
            <p>{t('your-role.step3.subtext2-1')}</p>
            <p>{t('your-role.step3.subtext2-2')}</p>
            <p>{t('your-role.step3.subtext2-3')}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default YourRole;
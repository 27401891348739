import React from 'react';
import './why.scss';
import {useTranslation} from 'react-i18next';

function Why() {

  const {t} = useTranslation();

  return (
    <section className="why dark">
      <div className="inner">
        <h2>{t('why.title')}</h2>
        <p>{t('why.text1')}</p>
        <p>{t('why.text2')}</p>
      </div>
    </section>
  );
}

export default Why;
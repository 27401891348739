import React, {useEffect, useState} from 'react';
import './hero.scss';
import {useTranslation} from 'react-i18next';
import ImageHero1280 from '../../../assets/img/deco-hero-1280.jpg';
import ImageHero767 from '../../../assets/img/deco-hero-767.jpg';

function Hero() {

  const {t} = useTranslation();

  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setAnchoPantalla(window.innerWidth);
    };

    // Agregar un listener para el evento de cambio de tamaño de pantalla
    window.addEventListener('resize', handleResize);

    // Eliminar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className="hero dark">
      <div className="video-wrapper">
        <div className="video">
          <iframe width="560" height="315" src={t('hero.video-url')}
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

      </div>
      <div className="inner">
        <h1>{t('hero.title')}</h1>
        <p>{t('hero.text')}</p>
      </div>
    </section>
  );
}
export default Hero;
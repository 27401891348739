import React from 'react';
import './members.scss';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from 'react-i18next';
import IcoClock from '../../../assets/img/icons/ico-clock.svg'
import IcoBuilding from '../../../assets/img/icons/ico-building.svg'
import IcoCheck from '../../../assets/img/icons/ico-check.svg'
import IcoInvoice from '../../../assets/img/icons/ico-invoice.svg'
import IcoOrderTracker from '../../../assets/img/icons/ico-order-tracker.svg'
import IcoWheel from '../../../assets/img/icons/ico-wheel.svg'

function Members() {

  const {t} = useTranslation();

  var settings = {
    responsive: [
      {
        breakpoint: 5000,
        settings: 'unslick'
      },
      {
        breakpoint: 767,
        settings: {

          dots: false,
          arrows: false,
          infinite: false,
          speed: 500,
          slidesToShow: 1.2,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };

  return (
    <section className="members">
      <div className="inner">
        <h2>{t('who.title')}</h2>
        <p>{t('who.text')}</p>
        <div className="requirements">
          <Slider {...settings}>
            <div style={{ width: 300 }} className="item">
              <span>
                <img src={IcoInvoice} alt=""/>
              </span>
              <p>{t('who.requirements.item1')}</p>
            </div>
            <div style={{ width: 300 }} className="item">
              <span>
                <img src={IcoOrderTracker} alt=""/>
              </span>
              <p>{t('who.requirements.item2')}</p>
            </div>
            <div style={{ width: 300 }} className="item">
              <span>
                <img src={IcoCheck} alt=""/>
              </span>
              <p>{t('who.requirements.item3')}</p>
            </div>
            <div style={{ width: 300 }} className="item">
              <span>
                <img src={IcoWheel} alt=""/>
              </span>
              <p>{t('who.requirements.item4')}</p>
            </div>
            <div style={{ width: 300 }} className="item">
              <span>
                <img src={IcoBuilding} alt=""/>
              </span>
              <p>{t('who.requirements.item5')}</p>
            </div>
            <div style={{ width: 300 }} className="item">
              <span>
                <img src={IcoClock} alt=""/>
              </span>
              <p>{t('who.requirements.item6')}</p>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Members;
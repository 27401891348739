import React from 'react';
import './intro.scss';
import {useTranslation} from 'react-i18next';

function Intro() {

  const {t} = useTranslation();

  return (
    <section className="intro">
      <div className="inner">
        <h2>{t('intro.title')}</h2>
        <p>{t('intro.text')}</p>
      </div>
    </section>
  );
}

export default Intro;
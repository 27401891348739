import React from 'react';
import './header.scss';
import LanguageSwitcher from '../../molecules/language-switcher/language-switcher';
import Logo from '../../../assets/img/logo.svg'

function Header() {
  return (
    <header className='App-header'>
      <img src={Logo} alt="Range Rover Colective" className="logo" />
      <LanguageSwitcher />
    </header>
  );
}
export default Header;